<template>
  <section class="excerpt-preview-wrapper">
    <div v-if="isLoading">
      Загрузка...
      <b-spinner
        class="mr-1"
        variant="primary"
      />
    </div>
    <div v-else>
      <!-- Alert: No item found -->
      <div
        v-if="!excerptDataObj"
        variant="danger"
      >
        <h4 class="alert-heading">
          Ошибка отображения файла
        </h4>
        <div class="alert-body">
          Файл не найден или отсутствует к нему доступ
        </div>
      </div>
      <div v-else>
        <type-09
          v-if="excerptType === 9"
          :excerpt-data="excerptData"
          :excerpt-data-obj="excerptDataObj"
          :excerpt-rights="excerptRights"
          :excerpt-type="excerptType"
        />
        <type-10
          v-else-if="excerptType === 10"
          :excerpt-data="excerptData"
          :excerpt-data-obj="excerptDataObj"
          :excerpt-rights="excerptRights"
          :excerpt-type="excerptType"
        />

        <div v-else>
          <h4 class="alert-heading">
            Нет отображения файла
          </h4>
          <div class="alert-body">
            Отображение файла пока не реализовано в архиве, скоро мы это исправим, спасибо за терпение.
            <br>
            JSON представление файла:
          </div>
        </div>
        <json-viewer
          :value="excerptData"
          :expand-depth="5"
          copyable
          boxed
          sort
          class="mt-1"
        />
      </div>
    </div>
    <excerpt-sidebar-send-excerpt />
    <excerpt-sidebar-add-payment />
  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  VBToggle, BSpinner, // BAlert,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import JsonViewer from 'vue-json-viewer'

import {
  printAdres, printAssignationCode, printAssignationType, printLevel, printDate,
  printParentCadastralNumbers, printConstructors, printState, printOwner,
  printStrArray, printOldNumbers, printDuration, pn, printCadastrCost,
  printCulturalHeritage, printObjectType, printOffspringObjects, printDoc,
  printRegistration,
} from '@core/utils/filter'
import Type09 from './Type09.vue'
import Type10 from './Type10.vue'

import excerptStoreModule from '../excerptStoreModule'
import ExcerptSidebarSendExcerpt from '../ExcerptSidebarSendExcerpt.vue'
import ExcerptSidebarAddPayment from '../ExcerptSidebarAddPayment.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    // BAlert,
    ExcerptSidebarAddPayment,
    ExcerptSidebarSendExcerpt,
    Type09,
    Type10,
    JsonViewer,
    BSpinner,
  },
  setup() {
    const excerptData = ref(null)
    const excerptDataObj = ref(null)
    const excerptRights = ref(null)
    const excerptType = ref({})
    const isLoading = ref(true)

    // Excerpt Description
    // ? Your real data will contain this information
    const excerptDescription = [
      {
        taskTitle: 'Native App Development',
        taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00',
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00',
      },
    ]

    const EXCERPT_APP_STORE_MODULE_NAME = 'app-excerpt'

    // Register module
    if (!store.hasModule(EXCERPT_APP_STORE_MODULE_NAME)) store.registerModule(EXCERPT_APP_STORE_MODULE_NAME, excerptStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EXCERPT_APP_STORE_MODULE_NAME)) store.unregisterModule(EXCERPT_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-excerpt/fetchExcerpt', { id: router.currentRoute.params.id })
      .then(response => {
        excerptData.value = response.data.Data.excerpt
        excerptType.value = response.data.Data.type
        // console.log(excerptData.value)
        if (excerptType.value === 9) {
          excerptDataObj.value = excerptData.value.Realty.Flat
        } else if (excerptType.value === 10) {
          if (excerptData.value.Parcels && excerptData.value.Parcels.Parcel) {
            excerptDataObj.value = excerptData.value.Parcels.Parcel
            excerptDataObj.value.isObject = false
          } else {
            excerptDataObj.value = excerptData.value.Object
            excerptDataObj.value.isObject = true
          }
        } else {
          excerptDataObj.value = excerptData.value
        }

        if (excerptDataObj.value && excerptDataObj.value.SubFlats
          && excerptDataObj.value.SubFlats.SubFlat) {
          excerptDataObj.value.SubFlats.SubFlat.sort((a, b) => {
            // eslint-disable-next-line radix
            if (parseInt(a.NumberRecord) > parseInt(b.NumberRecord)) return 1
            // eslint-disable-next-line radix
            return ((parseInt(b.NumberRecord) > parseInt(a.NumberRecord)) ? -1 : 0)
          })

          excerptDataObj.value.SubFlats.SubFlat.forEach(item => {
            if (item.Encumbrance) {
              const parts = []

              if (item.Encumbrance.Name) parts.push(item.Encumbrance.Name)
              if (item.Encumbrance.ShareText) parts.push(item.Encumbrance.ShareText)
              if (item.Encumbrance.RegDate) parts.push(`Дата государственной регистрации: ${item.Encumbrance.RegDate}`)
              if (item.Encumbrance.RegNumber) parts.push(`Номер государственной регистрации: ${item.Encumbrance.RegNumber}`)
              if (item.Encumbrance.Duration) parts.push(`Срок, на который установлено ограничение прав и обременение объекта недвижимости: ${printDuration(item.Encumbrance.Duration)}`)

              if (item.Encumbrance.Owner
                    && item.Encumbrance.Owner.length > 0) {
                const partsOwners = []
                for (let i = 0; i < item.Encumbrance.Owner.length; i += 1) {
                  const owner = item.Encumbrance.Owner[i]
                  partsOwners.push(printOwner(owner))
                }
                parts.push(`Лицо, в пользу которого установлено ограничение прав и обременение объекта недвижимости: ${partsOwners.join('; ')}`)
              }
              if (item.Encumbrance.DocFound
                    && item.Encumbrance.DocFound.length > 0) {
                const partsDoc = []
                for (let i = 0; i < item.Encumbrance.DocFound.length; i += 1) {
                  const doc = item.Encumbrance.DocFound[i]
                  partsDoc.push(printDoc(doc))
                }
                parts.push(`Основание государственной регистрации: ${partsDoc.join('; ')}`)
              }

              if (item.Encumbrance.OwnersRestrictionInFavorem
                    && item.Encumbrance.OwnersRestrictionInFavorem.OwnerRestrictionInFavorem
                    && item.Encumbrance.OwnersRestrictionInFavorem.OwnerRestrictionInFavorem.length > 0) {
                const partsRestOwners = []
                for (let i = 0; i < item.Encumbrance.OwnersRestrictionInFavorem.OwnerRestrictionInFavorem.length; i += 1) {
                  const owner = item.Encumbrance.OwnersRestrictionInFavorem.OwnerRestrictionInFavorem[i]
                  partsRestOwners.push(printOwner(owner))
                }
                parts.push(`Лица, в пользу которых ограничиваются (обременяются) права: ${partsRestOwners.join('; ')}`)
              }
              // eslint-disable-next-line no-param-reassign
              item.EncumbranceText = `${parts.join('. ')}.`
            } else {
              // eslint-disable-next-line no-param-reassign
              item.EncumbranceText = 'данные отсутствуют'
            }
          })
        }
        if (response.data.Data.excerpt
            && response.data.Data.excerpt.ReestrExtract
            && response.data.Data.excerpt.ReestrExtract.ExtractObjectRight
            && response.data.Data.excerpt.ReestrExtract.ExtractObjectRight.ExtractObject
            && response.data.Data.excerpt.ReestrExtract.ExtractObjectRight.ExtractObject.ObjectRight
            && response.data.Data.excerpt.ReestrExtract.ExtractObjectRight.ExtractObject.ObjectRight.Right) {
          excerptRights.value = response.data.Data.excerpt.ReestrExtract.ExtractObjectRight.ExtractObject.ObjectRight.Right

          for (let i = 0; i < excerptRights.value.length; i += 1) {
            if (!excerptRights.value[i].Owner || excerptRights.value[i].Owner.length === 0) {
              excerptRights.value[i].Owner = [
                {
                  Governance: null,
                  Organization: null,
                  Person: null,
                  ID_Subject: '-1',
                },
              ]
            }
          }
        }
        isLoading.value = false
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 404) {
          excerptData.value = undefined
        }
        isLoading.value = false
      })

    const printExcerpt = () => {
      window.print()
    }

    return {
      excerptData,
      excerptDataObj,
      excerptRights,
      excerptType,
      excerptDescription,
      printExcerpt,
      printAdres,
      printAssignationCode,
      printAssignationType,
      printLevel,
      printDate,
      printParentCadastralNumbers,
      printConstructors,
      printState,
      printOwner,
      printStrArray,
      printOldNumbers,
      printDuration,
      pn,
      printCadastrCost,
      printCulturalHeritage,
      printObjectType,
      printOffspringObjects,
      printDoc,
      printRegistration,
      fields: [
        { key: 'NumberRecord', label: 'Учетный номер части' },
        { key: 'Area', label: 'Площадь, м²' },
        { key: 'Location', label: 'Описание местоположения части' },
        { key: 'EncumbranceText', label: 'Содержание ограничения в использовании, ограничения права на объект недвижимости, обременения объекта недвижимости' },
      ],
      isLoading,
    }
  },
}
</script>

<style lang="scss" scope>
@import "~@core/scss/base/pages/app-excerpt.scss";
</style>

<style lang="scss" scope>
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
}
</style>
