<template>
  <b-card-body
    class="excerpt-padding"
  >
    <div class="mt-0">
      <div>
        <div class="logo-wrapper d-flex align-items-center">
          <h3 class="d-flex align-items-center">
            Правообладатель (правообладатели)
          </h3>
        </div>
        <div
          v-if="!excerptRights"
          variant="danger"
        >
          <h4 style="color: red">
            Данные о правообладателях <b>отсутствуют</b>
          </h4>
        </div>
        <div v-else>
          <app-timeline
            v-for="(right, r_index) in excerptRights"
            :key="right.RightNumber"
            class="mb-4"
          >
            <app-timeline-item
              v-for="(owner, o_index) in right.Owner"
              :key="owner.ID_Subject"
              icon="UserIcon"
            >
              <div>
                <h6 class="d-block">
                  {{ (r_index + 1) + "." + (o_index + 1) + ". " + printOwner(owner) }}
                </h6>
                <small class="d-block">{{ printRegistration(right.Registration) }}</small>
              </div>
            </app-timeline-item>

            <app-timeline-item
              v-if="right && right.Encumbrance && right.Encumbrance.length !== 0"
              icon="XIcon"
              variant="danger"
            >
              <div class="mb-1">
                <h6 class="d-block">
                  Ограничение прав и обременение объекта недвижимости
                  <b-badge
                    v-if="!right || !right.Encumbrance || right.Encumbrance.length === 0"
                    variant="success"
                  >
                    <div class="alert-body">
                      не зарегистрировано
                    </div>
                  </b-badge>
                </h6>
                <div>
                  <div
                    v-for="(encumbrance, e_index) in right.Encumbrance"
                    :key="encumbrance.ID_Record"
                  >
                    <div class="apply-job-package bg-light-danger rounded">
                      <div>
                        <h4 class="d-inline">
                          {{ (e_index + 1) + '. ' + encumbrance.Name + (encumbrance.ShareText ? ', ' + encumbrance.ShareText : '' ) }}
                        </h4>
                      </div>
                      <b-badge
                        variant="light-danger"
                        pill
                      >
                        от {{ encumbrance.RegDate }}
                      </b-badge>
                    </div>
                    <p class="card-text">
                      Дата государственной регистрации: <b>{{ encumbrance.RegDate }}</b>
                    </p>
                    <p class="card-text">
                      Номер государственной регистрации: <b>{{ encumbrance.RegNumber }}</b>
                    </p>
                    <p class="card-text">
                      Срок, на который установлено ограничение прав и обременение объекта недвижимости:
                      <b>
                        {{ printDuration(encumbrance.Duration) }}
                      </b>
                    </p>
                    <div class="card-text mb-1">
                      Лицо, в пользу которого установлено ограничение прав и обременение объекта недвижимости:
                      <b
                        v-if="!encumbrance || !encumbrance.Owner || encumbrance.Owner.length === 0"
                        class="ml-2"
                      >
                        <div class="alert-body">
                          данные отсутствуют
                        </div>
                      </b>
                      <div
                        v-for="owner in encumbrance.Owner"
                        :key="owner.ID_Subject"
                        class="ml-2"
                      >
                        <b>{{ printOwner(owner) }}</b>
                      </div>
                    </div>
                    <div class="card-text mb-1">
                      Основание государственной регистрации:
                      <b
                        v-if="!encumbrance || !encumbrance.DocFound || encumbrance.DocFound.length === 0"
                        class="ml-2"
                      >
                        <div class="alert-body">
                          данные отсутствуют
                        </div>
                      </b>
                      <div
                        v-for="docFound in encumbrance.DocFound"
                        :key="docFound.ID_Document"
                        class="ml-2"
                      >
                        <b>{{ printDoc(docFound) }}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </app-timeline-item>
          </app-timeline>
        </div>
      </div>
    </div>
  </b-card-body>
</template>

<script>
import {
  BCardBody, VBToggle, BBadge,
} from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'

import {
  printAdres, printAssignationCode, printAssignationType, printLevel, printDate,
  printParentCadastralNumbers, printConstructors, printState, printOwner,
  printStrArray, printOldNumbers, printDuration, pn, printCadastrCost,
  printCulturalHeritage, printObjectType, printOffspringObjects, printDoc,
  printRegistration,
} from '@core/utils/filter'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    BCardBody,
    BBadge,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    /* eslint-disable no-undef */
    excerptRights: Array,
  },
  setup() {
    return {
      printAdres,
      printAssignationCode,
      printAssignationType,
      printLevel,
      printDate,
      printParentCadastralNumbers,
      printConstructors,
      printState,
      printOwner,
      printStrArray,
      printOldNumbers,
      printDuration,
      pn,
      printCadastrCost,
      printCulturalHeritage,
      printObjectType,
      printOffspringObjects,
      printDoc,
      printRegistration,
      fields: [
        { key: 'NumberRecord', label: 'Учетный номер части' },
        { key: 'Area', label: 'Площадь, м²' },
        { key: 'Location', label: 'Описание местоположения части' },
        { key: 'EncumbranceText', label: 'Содержание ограничения в использовании, ограничения права на объект недвижимости, обременения объекта недвижимости' },
      ],
    }
  },
}
</script>
