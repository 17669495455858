<template>
  <b-row
    class="excerpt-preview"
  >

    <!-- Col: Left (Excerpt Container) -->
    <b-col
      cols="12"
      xl="12"
      md="12"
    >
      <b-card
        no-body
        class="excerpt-preview-card card-apply-job"
      >
        <!-- Header -->
        <b-card-body class="excerpt-padding pb-0">

          <div class="d-flex justify-content-between flex-column excerpt-spacing mt-0">
            <div>
              <div class="logo-wrapper d-flex align-items-center">
                <h3 class="d-flex align-items-center">
                  {{ "Отчёт об объекте недвижимости на " + printDate(excerptData.CertificationDoc.cer_Date) }}
                </h3>
              </div>
              <dl class="row">
                <dt class="col-sm-5">
                  Кадастровый номер
                </dt>
                <dd class="col-sm-7" style="color: #5e50ee">
                  {{ excerptDataObj.CadastralNumber }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Номер кадастрового квартала
                </dt>
                <dd class="col-sm-7">
                  {{ excerptDataObj.CadastralBlock }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Дата присвоения кадастрового номера
                </dt>
                <dd class="col-sm-7">
                  {{ printDate(excerptDataObj.DateCreated) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Ранее присвоенный государственный учетный номер
                </dt>
                <dd class="col-sm-7">
                  {{ printOldNumbers(excerptDataObj) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Адрес
                </dt>
                <dd class="col-sm-7">
                  {{ printAdres(excerptDataObj.Address, false) }}
                  <br>
                  <small>{{ printAdres(excerptDataObj.Address, true) }}</small>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Площадь
                </dt>
                <dd class="col-sm-7">
                  {{ excerptDataObj.Area }} м2
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Кадастровая стоимость
                </dt>
                <dd class="col-sm-7">
                  {{ printCadastrCost(excerptDataObj.CadastralCost) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Вид объекта недвижимости
                </dt>
                <dd class="col-sm-7">
                  {{ printObjectType(excerptDataObj.ObjectType) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Назначение/наименование
                </dt>
                <dd class="col-sm-7">
                  {{ printAssignationCode(excerptDataObj.Assignation) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Номер, тип этажа, на котором расположено помещение, машино-место
                </dt>
                <dd class="col-sm-7">
                  {{ printLevel(excerptDataObj.PositionInObject) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Вид жилого помещения
                </dt>
                <dd class="col-sm-7">
                  {{ printAssignationType(excerptDataObj) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Кадастровые номера иных объектов недвижимости, в пределах которых расположен объект недвижимости
                </dt>
                <dd class="col-sm-7">
                  {{ printParentCadastralNumbers(excerptDataObj.ParentCadastralNumbers) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Кадастровые номера объектов недвижимости, из которых образован объект недвижимости
                </dt>
                <dd class="col-sm-7">
                  {{ (excerptDataObj.PrevCadastralNumbers ? printStrArray(excerptDataObj.PrevCadastralNumbers.CadastralNumber) : 'данные отсутствуют') }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Кадастровые номера объектов недвижимости, образованных с объектом недвижимости
                </dt>
                <dd class="col-sm-7">
                  {{ (excerptDataObj.AllNewObjects && excerptDataObj.AllNewObjects.CadastralNumber ? printStrArray(excerptDataObj.AllNewObjects.CadastralNumber) : 'данные отсутствуют') }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Кадастровые номера объектов, подлежащих снятию с кадастрового учета
                </dt>
                <dd class="col-sm-7">
                  {{ (excerptDataObj.RemoveObjects && excerptDataObj.RemoveObjects.CadastralNumber ? printStrArray(excerptDataObj.RemoveObjects.CadastralNumber) : 'данные отсутствуют') }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Кадастровые номера образованных объектов недвижимости
                </dt>
                <dd class="col-sm-7">
                  {{ printOffspringObjects(excerptDataObj) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о включении объекта недвижимости в состав предприятия как имущественного комплекса
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о включении объекта недвижимости в состав единого недвижимого комплекса
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Виды разрешенного использования
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о включении объекта недвижимости в реестр объектов культурного наследия
                </dt>
                <dd class="col-sm-7">
                  {{ printCulturalHeritage(excerptDataObj.CulturalHeritage) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о кадастровом инженере
                </dt>
                <dd class="col-sm-7">
                  {{ printConstructors(excerptData.Contractors) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения об отнесении жилого помещения к определенному виду жилых помещений специализированного жилищного фонда, к жилым помещениям наемного дома социального использования или наемного дома коммерческого использования
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Статус записи об объекте недвижимости
                </dt>
                <dd class="col-sm-7">
                  {{ printState(excerptDataObj) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Особые отметки
                </dt>
                <dd class="col-sm-7">
                  {{ excerptDataObj.Notes ? excerptDataObj.Notes : 'данные отсутствуют' }}
                </dd>
              </dl>
            </div>
          </div>
        </b-card-body>

        <!-- Spacer -->
        <hr class="excerpt-spacing">
        <BlockRights :excerptRights="excerptRights"/>

        <hr
          v-if="excerptData
            && excerptData.ReestrExtract
            && excerptData.ReestrExtract.ExtractObjectRight
            && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject"
          class="excerpt-spacing"
        >

        <b-card-body
          v-if="excerptData
            && excerptData.ReestrExtract
            && excerptData.ReestrExtract.ExtractObjectRight
            && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject"
          class="excerpt-padding pb-0"
        >

          <div class="d-flex justify-content-between flex-column excerpt-spacing mt-0">
            <div>
              <dl class="row">
                <dt class="col-sm-5">
                  Заявленные в судебном порядке права требования
                </dt>
                <dd class="col-sm-7">
                  <span
                    v-if="excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightClaim && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightClaim != 'данные отсутствуют'"
                    style="color: red"
                  >
                    {{ excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightClaim }}
                  </span>
                  <span v-else>
                    данные отсутствуют
                  </span>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о возражении в отношении зарегистрированного права
                </dt>
                <dd class="col-sm-7">
                  <span
                    v-if="excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightAgainst && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightAgainst != 'данные отсутствуют'"
                    style="color: red"
                  >
                    {{ excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightAgainst }}
                  </span>
                  <span v-else>
                    данные отсутствуют
                  </span>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о наличии решения об изъятии объекта недвижимости для государственных и муниципальных нужд
                </dt>
                <dd class="col-sm-7">
                  <span
                    v-if="excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightSteal && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightSteal != 'данные отсутствуют'"
                    style="color: red"
                  >
                    {{ excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightSteal }}
                  </span>
                  <span v-else>
                    данные отсутствуют
                  </span>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о невозможности государственной регистрации без личного участия правообладателя или его законного представителя
                </dt>
                <dd class="col-sm-7">
                  <span
                    v-if="excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.InabilityRegWithoutOwner && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.InabilityRegWithoutOwner != 'данные отсутствуют'"
                    style="color: red"
                  >
                    {{ excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.InabilityRegWithoutOwner }}
                  </span>
                  <span v-else>
                    данные отсутствуют
                  </span>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Правопритязания и сведения о наличии поступивших, но не рассмотренных заявлений о проведении государственной регистрации права (перехода, прекращения права), ограничения права или обременения объекта недвижимости, сделки в отношении объекта недвижимости
                </dt>
                <dd class="col-sm-7">
                  <span
                    v-if="excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightAssert && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightAssert != 'данные отсутствуют'"
                    style="color: red"
                  >
                    {{ excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightAssert }}
                  </span>
                  <span v-else>
                    данные отсутствуют
                  </span>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения об осуществлении государственной регистрации сделки, права, ограничения права без необходимого в силу закона согласия третьего лица, органа
                </dt>
                <dd class="col-sm-7">
                  <span
                    v-if="excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.WithoutThirdParty && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.WithoutThirdParty != 'данные отсутствуют'"
                    style="color: red"
                  >
                    {{ excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.WithoutThirdParty }}
                  </span>
                  <span v-else>
                    данные отсутствуют
                  </span>
                </dd>
              </dl>
            </div>
          </div>
        </b-card-body>

        <hr class="excerpt-spacing">

        <b-card-body class="excerpt-padding pb-0">

          <div class="d-flex justify-content-between flex-column excerpt-spacing mt-0">
            <div>
              <dl class="row">
                <dt class="col-sm-5">
                  Система координат
                </dt>
                <dd class="col-sm-7">
                  {{ excerptData.CoordSystems && excerptData.CoordSystems.spa_CoordSystem && excerptData.CoordSystems.spa_CoordSystem.Name ? excerptData.CoordSystems.spa_CoordSystem.Name : 'данные отсутствуют' }}
                </dd>
              </dl>
            </div>
          </div>
        </b-card-body>

        <!-- Spacer -->
        <hr
          v-if="excerptDataObj.SubFlats && excerptDataObj.SubFlats.SubFlat"
          class="excerpt-spacing"
        >

        <b-card-body
          v-if="excerptDataObj.SubFlats && excerptDataObj.SubFlats.SubFlat"
          class="excerpt-padding"
        >
          <div class="mt-0">
            <div>
              <div class="logo-wrapper d-flex align-items-center">
                <h3 class="d-flex align-items-center">
                  Сведения о части (частях) помещения
                </h3>
              </div>
              <b-table
                responsive="sm"
                :fields="fields"
                :items="excerptDataObj.SubFlats.SubFlat"
              >
                <template #cell(Location)="data">
                  <span>{{ data.value ? data.value : 'данные отсутствуют' }}</span>
                </template>
                <template #cell(Encumbrance)="data">
                  <span>{{ data.value ? data.value : 'данные отсутствуют' }}</span>
                </template>
              </b-table>
            </div>
          </div>
        </b-card-body>

      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, VBToggle, BTable,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import {
  printAdres, printAssignationCode, printAssignationType, printLevel, printDate,
  printParentCadastralNumbers, printConstructors, printState, printOwner,
  printStrArray, printOldNumbers, printDuration, pn, printCadastrCost,
  printCulturalHeritage, printObjectType, printOffspringObjects, printDoc,
  printRegistration,
} from '@core/utils/filter'
import BlockRights from './BlockRights.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTable,
    BlockRights,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    /* eslint-disable no-undef */
    excerptData: Object,
    excerptDataObj: Object,
    excerptRights: Array,
    excerptType: Number,
  },
  setup() {
    return {
      printAdres,
      printAssignationCode,
      printAssignationType,
      printLevel,
      printDate,
      printParentCadastralNumbers,
      printConstructors,
      printState,
      printOwner,
      printStrArray,
      printOldNumbers,
      printDuration,
      pn,
      printCadastrCost,
      printCulturalHeritage,
      printObjectType,
      printOffspringObjects,
      printDoc,
      printRegistration,
      fields: [
        { key: 'NumberRecord', label: 'Учетный номер части' },
        { key: 'Area', label: 'Площадь, м²' },
        { key: 'Location', label: 'Описание местоположения части' },
        { key: 'EncumbranceText', label: 'Содержание ограничения в использовании, ограничения права на объект недвижимости, обременения объекта недвижимости' },
      ],
    }
  },
}
</script>
