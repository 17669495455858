<template>
  <b-row
    class="excerpt-preview"
  >

    <!-- Col: Left (Excerpt Container) -->
    <b-col
      cols="12"
      xl="12"
      md="12"
    >
      <b-card
        no-body
        class="excerpt-preview-card card-apply-job"
      >
        <!-- Header -->
        <b-card-body class="excerpt-padding pb-0">

          <div class="d-flex justify-content-between flex-column excerpt-spacing mt-0">
            <div>
              <div class="logo-wrapper d-flex align-items-center">
                <h3 class="d-flex align-items-center">
                  {{ "Отчёт об объекте недвижимости на " + printDate(excerptData.ReestrExtract.DeclarAttribute.ExtractDate) }}
                </h3>
              </div>
              <dl class="row">
                <dt class="col-sm-5">
                  Кадастровый номер
                </dt>
                <dd class="col-sm-7" :style="{ color: !excerptDataObj.isObject ? '#5e50ee' : 'red' }">
                  {{ excerptDataObj.CadastralNumber }} {{ excerptDataObj.isObject ? " (статус Аннулированный)" : "" }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Вид объекта недвижимости
                </dt>
                <dd class="col-sm-7">
                  Земельный участок {{ printZUName(excerptDataObj) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Номер кадастрового квартала
                </dt>
                <dd class="col-sm-7">
                  {{ printCadastralBlock(excerptDataObj) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Дата присвоения кадастрового номера
                </dt>
                <dd class="col-sm-7">
                  {{ excerptDataObj.DateCreatedDoc ? printDate(excerptDataObj.DateCreatedDoc) : (excerptDataObj.DateCreated ? printDate(excerptDataObj.DateCreated) : 'данные отсутствуют') }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Ранее присвоенный государственный учетный номер:
                </dt>
                <dd class="col-sm-7">
                  <span v-if="!excerptDataObj.isObject">
                    {{ printOldNumbers(excerptDataObj) }}
                  </span>
                  <span v-else>
                    {{ excerptDataObj.CadastralNumber + (excerptDataObj.ConditionalNumber ? '; ' + excerptDataObj.ConditionalNumber : '') }}
                  </span>
                </dd>
              </dl>
              <dl class="row" v-if="excerptDataObj.Location && excerptDataObj.Location.inBounds && excerptDataObj.Location.inBounds != '2'">
                <dt class="col-sm-5">
                  Местоположение
                </dt>
                <dd class="col-sm-7">
                  <div>
                    Установлено относительно ориентира, расположенного
                    {{ (excerptDataObj.Location.inBounds == '1' ? 'в границах участка. ' : '') }}
                    {{ (excerptDataObj.Location.inBounds == '0' ? 'за пределами участка. ' : '') }}
                  </div>
                  <span v-if="excerptDataObj.Location.Elaboration && excerptDataObj.Location.Elaboration.ReferenceMark">
                    <br>
                    Наименование ориентира:
                    <b>
                      {{ excerptDataObj.Location.Elaboration.ReferenceMark }}
                    </b>
                  </span>
                  <span v-if="excerptDataObj.Location.Elaboration && excerptDataObj.Location.Elaboration.Direction">
                    <br>
                    Направление от ориентира:
                    <b>
                      {{ excerptDataObj.Location.Elaboration.Direction }}
                    </b>
                  </span>
                  <span v-if="excerptDataObj.Location.Elaboration && excerptDataObj.Location.Elaboration.Distance">
                    <br>
                    Расстояние до объекта:
                    <b>
                      {{ excerptDataObj.Location.Elaboration.Distance }}
                    </b>
                  </span>
                </dd>
              </dl>
              <dl class="row" v-if="excerptDataObj.Location && excerptDataObj.Location.inBounds && excerptDataObj.Location.inBounds != '2'">
                <dt class="col-sm-5">
                  Почтовый адрес ориентира
                </dt>
                <dd class="col-sm-7">
                  {{ printAdres(excerptDataObj.Location.Address, false) }}.
                  <br>
                  <small>{{ printAdres(excerptDataObj.Location.Address, true) }}.</small>
                </dd>
              </dl>
              <dl v-else class="row">
                <dt class="col-sm-5">
                  Адрес
                </dt>
                <dd class="col-sm-7">
                  <b>
                    {{ printAdres(excerptDataObj.Location ? excerptDataObj.Location.Address : excerptDataObj.Address, false) }}.
                    <br>
                    <small>{{ printAdres(excerptDataObj.Location ? excerptDataObj.Location.Address : excerptDataObj.Address, true) }}.</small>
                  </b>
                  <br v-if="excerptDataObj.Location && excerptDataObj.Location.Placed">
                  <span v-if="excerptDataObj.Location && excerptDataObj.Location.Placed">Положение на ДКК: <b>{{ excerptDataObj.Location.Placed }}</b></span>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Площадь
                </dt>
                <dd class="col-sm-7">
                  {{ printArea(excerptDataObj.Area) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Кадастровая стоимость
                </dt>
                <dd class="col-sm-7">
                  {{ printCadastrCost(excerptDataObj.CadastralCost) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Кадастровые номера расположенных в пределах земельного участка объектов недвижимости
                </dt>
                <dd class="col-sm-7">
                  {{ (excerptDataObj.InnerCadastralNumbers ? printStrArray(excerptDataObj.InnerCadastralNumbers.CadastralNumber) : 'данные отсутствуют') }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Кадастровые номера объектов недвижимости, из которых образован объект недвижимости
                </dt>
                <dd class="col-sm-7">
                  {{ (excerptDataObj.PrevCadastralNumbers ? printStrArray(excerptDataObj.PrevCadastralNumbers.CadastralNumber) : 'данные отсутствуют') }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Кадастровые номера образованных объектов недвижимости
                </dt>
                <dd class="col-sm-7">
                  {{ printOffspringObjects(excerptDataObj) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о включении объекта недвижимости в состав предприятия как имущественного комплекса
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Категория земель
                </dt>
                <dd class="col-sm-7">
                  <span v-if="excerptDataObj.isObject">
                    {{ excerptDataObj.GroundCategoryText ? excerptDataObj.GroundCategoryText : 'данные отсутствуют' }}
                  </span>
                  <span v-else>{{ printCategoryZU(excerptDataObj) }}</span>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Виды разрешенного использования
                </dt>
                <dd class="col-sm-7">
                  {{ printUtilization(excerptDataObj) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о кадастровом инженере
                </dt>
                <dd class="col-sm-7">
                  {{ printConstructors(excerptData.Contractors) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о лесах, водных объектах и об иных природных объектах, расположенных в пределах земельного участка
                </dt>
                <dd class="col-sm-7">
                  {{ printNaturalObject(excerptDataObj) }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о том, что земельный участок полностью или частично расположен в границах зоны с особыми условиями использования территории или территории объекта культурного наследия
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о том, что земельный участок расположен в границах особой экономической зоны, территории опережающего социально-экономического развития, зоны территориального развития в Российской Федерации, игорной зоны
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о том, что земельный участок расположен в границах особо охраняемой природной территории, охотничьих угодий, лесничеств, лесопарков
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о результатах проведения государственного земельного надзора
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о расположении земельного участка в границах территории, в отношении которой утвержден проект межевания территории
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Условный номер замельного участка
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о принятии акта и (или) заключении договора, предусматривающих предоставление в соответствии с земельным законодательством исполнительным органом государственной власти или органом местного самоуправления находящегося в государственной или муниципальной собственности земельного участка для строительства наемного дома социального использования или наемного дома коммерческого использования
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о том, что земельный участок или земельные участки образованы на основании решения об изъятии земельного участка и (или) расположенного на нем объекта недвижимости для государственных или муниципальных нужд
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о том, что земельный участок образован из земель или земельного участка, государственная собственность на которые не разграничена
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о наличии земельного спора о местоположении границ земельных участков
                </dt>
                <dd class="col-sm-7">
                  данные отсутствуют
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Статус записи об объекте недвижимости
                </dt>
                <dd class="col-sm-7">
                  {{ printState(excerptDataObj) }}
                </dd>
              </dl>
              <dl>
                <dt>
                  Особые отметки
                </dt>
                <dd>
                  <div v-if="excerptDataObj.isObject">
                    Сведения об объекте недвижимости сформированы по данным ранее внесенным в Единый Государственный реестр прав.
                  </div>
                  <span>{{ excerptDataObj.SpecialNote ? excerptDataObj.SpecialNote : 'данные отсутствуют' }}</span>
                </dd>
              </dl>
              <div v-if="(excerptDataObj.Contours && excerptDataObj.Contours.Contour && excerptDataObj.Contours.Contour.length > 0) || (excerptDataObj.CompositionEZ && excerptDataObj.CompositionEZ.EntryParcel && excerptDataObj.CompositionEZ.EntryParcel.length > 0)">
                <dl v-if="excerptDataObj.Contours && excerptDataObj.Contours.Contour && excerptDataObj.Contours.Contour.length > 0">
                  <dt>
                    Состав земельного участка
                  </dt>
                  <dd>
                    <div class="row">
                      <div
                        v-for="(obj, index) in excerptDataObj.Contours.Contour"
                        :key="index"
                        class="col-4 col-xs-6"
                      >
                        <b :style="{ color: (obj.State === '07' || obj.State === '08' ? 'red' : false) }">№ {{ index + 1 }}.</b> {{ printArea(obj.Area) }} {{ obj.State === '07' || obj.State === '08' ? '(снят с учёта ' + printDate(obj.DateRemoved) + ')' : '' }}
                      </div>
                    </div>
                  </dd>
                </dl>
                <dl v-if="excerptDataObj.CompositionEZ && excerptDataObj.CompositionEZ.EntryParcel && excerptDataObj.CompositionEZ.EntryParcel.length > 0">
                  <dt>
                    Список кадастровых номеров (площадей) обособленных (условных) участков, входящих в единое землепользование
                  </dt>
                  <dd>
                    <div class="row">
                      <div
                        v-for="(obj, index) in excerptDataObj.CompositionEZ.EntryParcel"
                        :key="index"
                        cols="4"
                        class="col-4 col-xs-6"
                      >
                        <b :style="{ color: (obj.State === '07' || obj.State === '08' ? 'red' : false) }">№ {{ obj.CadastralNumber }}.</b> {{ printArea(obj.Area) }} {{ obj.State === '07' || obj.State === '08' ? '(снят с учёта ' + printDate(obj.DateRemoved) + ')' : '' }}
                      </div>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </b-card-body>

        <hr class="excerpt-spacing">

        <b-card-body class="excerpt-padding pb-0">

          <div class="d-flex justify-content-between flex-column excerpt-spacing mt-0 mb-0">
            <div>
              <dl class="row">
                <dt class="col-sm-5">
                  Системы координат
                </dt>
                <dd class="col-sm-7">
                  {{ printCoordSystems(excerptData) }}
                </dd>
              </dl>
            </div>
          </div>
        </b-card-body>

        <hr
          v-if="excerptData
            && excerptData.ReestrExtract
            && excerptData.ReestrExtract.ExtractObjectRight
            && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject"
          class="excerpt-spacing mb-0"
        >

        <b-card-body
          v-if="excerptData
            && excerptData.ReestrExtract
            && excerptData.ReestrExtract.ExtractObjectRight
            && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject"
          class="excerpt-padding pb-0"
        >

          <div class="d-flex justify-content-between flex-column excerpt-spacing mt-0">
            <div>
              <dl class="row">
                <dt class="col-sm-5">
                  Заявленные в судебном порядке права требования
                </dt>
                <dd class="col-sm-7">
                  <span
                    v-if="excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightClaim && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightClaim != 'данные отсутствуют'"
                    style="color: red"
                  >
                    {{ excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightClaim }}
                  </span>
                  <span v-else>данные отсутствуют</span>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о возражении в отношении зарегистрированного права
                </dt>
                <dd class="col-sm-7">
                  <span
                    v-if="excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightAgainst && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightAgainst != 'данные отсутствуют'"
                    style="color: red"
                  >
                    {{ excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightAgainst }}
                  </span>
                  <span v-else>данные отсутствуют</span>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о наличии решения об изъятии объекта недвижимости для государственных и муниципальных нужд
                </dt>
                <dd class="col-sm-7">
                  <span
                    v-if="excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightSteal && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightSteal != 'данные отсутствуют'"
                    style="color: red"
                  >
                    {{ excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightSteal }}
                  </span>
                  <span v-else>данные отсутствуют</span>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения о невозможности государственной регистрации без личного участия правообладателя или его законного представителя
                </dt>
                <dd class="col-sm-7">
                  <span
                    v-if="excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.InabilityRegWithoutOwner && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.InabilityRegWithoutOwner != 'данные отсутствуют'"
                    style="color: red"
                  >
                    {{ excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.InabilityRegWithoutOwner }}
                  </span>
                  <span v-else>данные отсутствуют</span>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Правопритязания и сведения о наличии поступивших, но не рассмотренных заявлений о проведении государственной регистрации права (перехода, прекращения права), ограничения права или обременения объекта недвижимости, сделки в отношении объекта недвижимости
                </dt>
                <dd class="col-sm-7">
                  <span
                    v-if="excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightAssert && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightAssert != 'данные отсутствуют'"
                    style="color: red"
                  >
                    {{ excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.RightAssert }}
                  </span>
                  <span v-else>данные отсутствуют</span>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5">
                  Сведения об осуществлении государственной регистрации сделки, права, ограничения права без необходимого в силу закона согласия третьего лица, органа
                </dt>
                <dd class="col-sm-7">
                  <span
                    v-if="excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.WithoutThirdParty && excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.WithoutThirdParty != 'данные отсутствуют'"
                    style="color: red"
                  >
                    {{ excerptData.ReestrExtract.ExtractObjectRight.ExtractObject.WithoutThirdParty }}
                  </span>
                  <span v-else>данные отсутствуют</span>
                </dd>
              </dl>
            </div>
          </div>
        </b-card-body>

        <!-- Spacer -->
        <hr class="excerpt-spacing">
        <BlockRights :excerpt-rights="excerptRights" />

        <hr
          v-if="allChartsInfo.EntitySpatial.spa_SpatialElement.length > 0"
          class="excerpt-spacing"
        >
        <div
          v-if="allChartsInfo.EntitySpatial.spa_SpatialElement.length > 0"
          class="row px-3"
        >
          <div class="col-sm-12 col-xs-12 mb-2 logo-wrapper">
            <h3 class="d-flex align-items-center">
              Общий чертёж для всех участков
            </h3>
          </div>
          <div
            class=" col-sm-12 col-xs-12 mb-2"
          >
            <PrintCanvas
              :parcel="allChartsInfo"
              :kad="'global'"
            />
          </div>
        </div>

        <hr
          v-if="chartsInfo && chartsInfo.length > 0"
          class="excerpt-spacing"
        >
        <div
          v-if="chartsInfo && chartsInfo.length > 0"
          class="row px-3"
        >
          <div class="col-sm-12 col-xs-12 mb-2 logo-wrapper">
            <h3 class="d-flex align-items-center">
              Описание местоположения земельных участков
            </h3>
          </div>
          <div
            v-for="obj in chartsInfo"
            :key="obj.CadastralNumber"
            class=" col-sm-6 col-xs-12 mb-2"
          >
            <PrintCanvas
              :parcel="obj"
              :kad="obj.CadastralNumber || obj.NumberRecord"
            />
          </div>
        </div>

        <hr
          v-if="borders && borders.length"
          class="exvrrpt-spacing"
        >

        <b-card-body
          v-if="borders && borders.length"
          class="excerpt-padding"
        >
          <div class="mt-0">
            <div>
              <div class="logo-wrapper d-flex align-items-center">
                <h3 class="d-flex align-items-center">
                  Описание частей границ
                </h3>
              </div>
              <app-collapse accordion>
                <app-collapse-item
                  v-for="item in borders"
                  :key="item.kad"
                  :title="item.kad + ' | Границ: ' + item.borders.length + ' шт.'"
                >
                  <b-table
                    responsive="sm"
                    :items="item.borders"
                    :fields="bordersFields"
                  >
                    <template #cell(index)="data">
                      <span>{{ data.index + 1 }}</span>
                    </template>
                  </b-table>
                </app-collapse-item>
              </app-collapse>
            </div>
          </div>
        </b-card-body>

        <!-- Spacer -->
        <hr
          v-if="points && points.length > 0"
          class="excerpt-spacing"
        >

        <b-card-body
          v-if="points && points.length > 0"
          class="excerpt-padding"
        >
          <div class="mt-0">
            <div>
              <div class="logo-wrapper d-flex align-items-center">
                <h3 class="d-flex align-items-center">
                  Координаты земельных участков
                </h3>
              </div>
              <b-table
                :current-page="currentPage"
                responsive="sm"
                :fields="fieldsPoints"
                :items="points"
                per-page="20"
                first-number
              />
              <b-pagination
                v-model="currentPage"
                :total-rows="pointsLength"
                per-page="20"
                last-number
              />
            </div>
          </div>
        </b-card-body>

        <hr
          v-if="subChartsInfo && subChartsInfo.length > 0"
          class="excerpt-spacing"
        >
        <div
          v-if="subChartsInfo && subChartsInfo.length > 0"
          class="row px-3"
        >
          <div class="col-sm-12 col-xs-12 mb-2 logo-wrapper">
            <h3 class="d-flex align-items-center">
              Сведения о частях земельных участков
            </h3>
          </div>
          <div
            v-for="(obj, index) in subChartsInfo"
            :key="obj.CadastralNumber"
            class=" col-sm-6 col-xs-12 mb-2"
          >
            <PrintCanvas
              :parcel="obj"
              :kad="obj.CadastralNumber || obj.NumberRecord"
              :canvasId="index"
            />
          </div>
          <div class="col-sm-12 col-xs-12 mb-2">
            <b-table
              :current-page="currentSubChartsPage"
              responsive="sm"
              :items="subChartsInfo"
              :fields="subChartsFields"
              per-page="20"
            >
              <template #cell(Encumbrance)="data">
                {{ printEncumbrance(data.item) }}
              </template>
              <template #cell(Area)="data">
                <div v-if="data.item.Full && data.item.Full === '1'">
                  Весь
                </div>
                <div v-else>
                  {{ data.item.Area.Area }}
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentSubChartsPage"
              :total-rows="subChartsInfoLength"
              per-page="20"
              last-number
            />
          </div>
        </div>

        <hr
          v-if="points && points.length > 0"
          class="excerpt-spacing"
        >

        <b-card-body
          v-if="subPoints && subPoints.length > 0"
          class="excerpt-padding"
        >
          <div class="mt-0">
            <div>
              <div class="logo-wrapper d-flex align-items-center">
                <h3 class="d-flex align-items-center">
                  Координаты частей земельных участков
                </h3>
              </div>
              <app-collapse accordion>
                <app-collapse-item
                  v-for="(item, index) in subPoints"
                  :key="index"
                  :title="item.title"
                >
                  <subTablePaggination :items="item.arr" />
                </app-collapse-item>
              </app-collapse>
            </div>
          </div>
        </b-card-body>

        <!-- Spacer -->
        <hr
          v-if="excerptDataObj.SubFlats && excerptDataObj.SubFlats.SubFlat"
          class="excerpt-spacing"
        >

        <b-card-body
          v-if="excerptDataObj.SubFlats && excerptDataObj.SubFlats.SubFlat"
          class="excerpt-padding"
        >
          <div class="mt-0">
            <div>
              <div class="logo-wrapper d-flex align-items-center">
                <h3 class="d-flex align-items-center">
                  Сведения о части (частях) помещения
                </h3>
              </div>
              <b-table
                responsive="sm"
                :fields="fields"
                :items="excerptDataObj.SubFlats.SubFlat"
              >
                <template #cell(Location)="data">
                  <span>{{ data.value ? data.value : 'данные отсутствуют' }}</span>
                </template>
                <template #cell(Encumbrance)="data">
                  <span>{{ data.value ? data.value : 'данные отсутствуют' }}</span>
                </template>
              </b-table>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, VBToggle, BTable, BPagination,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'

import { computed } from '@vue/composition-api'

import {
  printAdres, printAssignationCode, printAssignationType, printLevel, printDate,
  printParentCadastralNumbers, printConstructors, printState, printOwner,
  printStrArray, printOldNumbers, printDuration, pn, printCadastrCost,
  printCulturalHeritage, printObjectType, printOffspringObjects, printDoc,
  printRegistration, printArea, printCadastralBlock, printCategoryZU,
  printCoordSystems, printUtilization, printZUName, printNaturalObject,
  printEncumbrance,
} from '@core/utils/filter'
import BlockRights from './BlockRights.vue'
import PrintCanvas from './PrintCanvas.vue'
import subTablePaggination from './subTablePaggination.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BlockRights,
    PrintCanvas,
    AppCollapse,
    AppCollapseItem,
    subTablePaggination,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    /* eslint-disable no-undef */
    excerptData: Object,
    excerptDataObj: Object,
    excerptRights: Array,
    excerptType: Number,
  },
  computed: {
    chartsInfo() {
      let arr = []
      if (this.excerptDataObj.EntitySpatial && this.excerptDataObj.EntitySpatial.spa_SpatialElement && this.excerptDataObj.EntitySpatial.spa_SpatialElement.length > 0) {
        arr.push({ EntitySpatial: this.excerptDataObj.EntitySpatial, CadastralNumber: this.excerptDataObj.CadastralNumber })
      }
      if (this.excerptDataObj.CompositionEZ && this.excerptDataObj.CompositionEZ.EntryParcel && this.excerptDataObj.CompositionEZ.EntryParcel.length > 0) {
        arr = arr.concat(this.excerptDataObj.CompositionEZ.EntryParcel)
      }
      if (this.excerptDataObj.Contours && this.excerptDataObj.Contours.Contour && this.excerptDataObj.Contours.Contour.length > 0) {
        arr = arr.concat(this.excerptDataObj.Contours.Contour)
      }
      return arr
    },
    allChartsInfo() {
      const result = {}
      result.EntitySpatial = {}
      result.EntitySpatial.spa_SpatialElement = []
      for (let i = 0; i < this.chartsInfo.length; i += 1) {
        if (this.chartsInfo[i].EntitySpatial && this.chartsInfo[i].EntitySpatial.spa_SpatialElement) {
          for (let j = 0; j < this.chartsInfo[i].EntitySpatial.spa_SpatialElement.length; j += 1) {
            result.EntitySpatial.spa_SpatialElement.push(this.chartsInfo[i].EntitySpatial.spa_SpatialElement[j])
          }
        }
      }
      return result
    },
    subChartsInfo() {
      let arr = []
      if (this.excerptDataObj.SubParcels && this.excerptDataObj.SubParcels.SubParcel && this.excerptDataObj.SubParcels.SubParcel.length > 0) {
        arr = arr.concat(this.excerptDataObj.SubParcels.SubParcel)
      }
      return arr
    },
    pointsLength() {
      return this.points.length
    },
    subChartsInfoLength() {
      return this.subChartsInfo.length
    },
    points() {
      const arr = []
      let indexObj = {}
      if (this.excerptDataObj.EntitySpatial && this.excerptDataObj.EntitySpatial.spa_SpatialElement && this.excerptDataObj.EntitySpatial.spa_SpatialElement.length > 0) {
        for (let i = 0; i < this.excerptDataObj.EntitySpatial.spa_SpatialElement.length; i += 1) {
          for (let j = 0; j < this.excerptDataObj.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit.length; j += 1) {
            const unit = this.excerptDataObj.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit[j]
            if (!indexObj[unit.SuNmb]) {
              indexObj[unit.SuNmb] = 1
              arr.push(unit)
            }
          }
        }
      }
      /*
      if (this.excerptDataObj.SubParcels && this.excerptDataObj.SubParcels.SubParcel && this.excerptDataObj.SubParcels.SubParcel.length > 0) {
        for (let k = 0; k < this.excerptDataObj.SubParcels.SubParcel.length; k += 1) {
          for (let i = 0; i < this.excerptDataObj.SubParcels.SubParcel[k].EntitySpatial.spa_SpatialElement.length; i += 1) {
            for (let j = 0; j < this.excerptDataObj.SubParcels.SubParcel[k].EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit.length; j += 1) {
              const unit = this.excerptDataObj.SubParcels.SubParcel[k].EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit[j]
              if (!indexObj[unit.SuNmb]) {
                indexObj[unit.SuNmb] = 1
                arr.push(unit)
              }
            }
          }
        }
      }
      */

      if (this.excerptDataObj.CompositionEZ && this.excerptDataObj.CompositionEZ.EntryParcel && this.excerptDataObj.CompositionEZ.EntryParcel.length > 0) {
        for (let k = 0; k < this.excerptDataObj.CompositionEZ.EntryParcel.length; k += 1) {
          const ep = this.excerptDataObj.CompositionEZ.EntryParcel[k]
          if (ep && ep.EntitySpatial && ep.EntitySpatial.spa_SpatialElement && ep.EntitySpatial.spa_SpatialElement.length > 0) {
            for (let i = 0; i < ep.EntitySpatial.spa_SpatialElement.length; i += 1) {
              for (let j = 0; j < ep.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit.length; j += 1) {
                const unit = ep.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit[j]
                if (!indexObj[unit.SuNmb]) {
                  indexObj[unit.SuNmb] = 1
                  arr.push(unit)
                }
              }
            }
          }
        }
      }

      if (this.excerptDataObj.Contours && this.excerptDataObj.Contours.Contour && this.excerptDataObj.Contours.Contour.length > 0) {
        for (let k = 0; k < this.excerptDataObj.Contours.Contour.length; k += 1) {
          const co = this.excerptDataObj.Contours.Contour[k]
          if (co && co.EntitySpatial && co.EntitySpatial.spa_SpatialElement && co.EntitySpatial.spa_SpatialElement.length > 0) {
            for (let i = 0; i < co.EntitySpatial.spa_SpatialElement.length; i += 1) {
              for (let j = 0; j < co.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit.length; j += 1) {
                const unit = co.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit[j]
                if (!indexObj[unit.SuNmb]) {
                  indexObj[unit.SuNmb] = 1
                  arr.push(unit)
                }
              }
            }
          }
        }
      }

      if (this.excerptData.Parcels.OffspringParcel && this.excerptData.Parcels.OffspringParcel.length > 0) {
        for (let k = 0; k < this.excerptData.Parcels.OffspringParcel.length; k += 1) {
          const op = this.excerptData.Parcels.OffspringParcel[k]
          if (op && op.EntitySpatial && op.EntitySpatial.spa_SpatialElement && op.EntitySpatial.spa_SpatialElement.length > 0) {
            for (let i = 0; i < op.EntitySpatial.spa_SpatialElement.length; i += 1) {
              for (let j = 0; j < op.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit.length; j += 1) {
                const unit = op.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit[j]
                if (!indexObj[unit.SuNmb]) {
                  indexObj[unit.SuNmb] = 1
                  arr.push(unit)
                }
              }
            }
          }
        }
      }
      indexObj = {}
      // eslint-disable-next-line radix
      // eslint-disable-next-line no-nested-ternary
      return arr.sort((a, b) => {
        if (parseInt(a.SuNmb, 0) > parseInt(b.SuNmb, 0)) {
          return 1
        }
        if (parseInt(b.SuNmb, 0) > parseInt(a.SuNmb, 0)) {
          return -1
        }
        return 0
      })
    },
    borders() {
      const arr = []
      if (this.excerptDataObj.EntitySpatial
      && this.excerptDataObj.EntitySpatial.spa_Borders
      && this.excerptDataObj.EntitySpatial.spa_Borders.spa_Border) {
        arr.push({ kad: this.excerptDataObj.CadastralNumber, borders: this.excerptDataObj.EntitySpatial.spa_Borders.spa_Border })
      }
      if (this.excerptDataObj.SubParcels && this.excerptDataObj.SubParcels.SubParcel) {
        for (let i = 0; i < this.excerptDataObj.SubParcels.SubParcel.length; i += 1) {
          if (this.excerptDataObj.SubParcels.SubParcel[i].EntitySpatial
          && this.excerptDataObj.SubParcels.SubParcel[i].EntitySpatial.spa_Borders
          && this.excerptDataObj.SubParcels.SubParcel[i].EntitySpatial.spa_Borders.spa_Border) {
            arr.push({ kad: `№${this.excerptDataObj.SubParcels.SubParcel[i].NumberRecord}`, borders: this.excerptDataObj.SubParcels.SubParcel[i].EntitySpatial.spa_Borders.spa_Border })
          }
        }
      }
      if (this.excerptDataObj.CompositionEZ && this.excerptDataObj.CompositionEZ.EntryParcel) {
        let ind = 0
        for (let i = 0; i < this.excerptDataObj.CompositionEZ.EntryParcel.length; i += 1) {
          if (this.excerptDataObj.CompositionEZ.EntryParcel[i].EntitySpatial
          && this.excerptDataObj.CompositionEZ.EntryParcel[i].EntitySpatial.spa_Borders
          && this.excerptDataObj.CompositionEZ.EntryParcel[i].EntitySpatial.spa_Borders.spa_Border) {
            ind += 1
            arr.push({ kad: `№${ind} ${this.excerptDataObj.CompositionEZ.EntryParcel[i].CadastralNumber}`, borders: this.excerptDataObj.CompositionEZ.EntryParcel[i].EntitySpatial.spa_Borders.spa_Border })
          }
        }
      }

      if (this.excerptDataObj.Contours && this.excerptDataObj.Contours.Contour) {
        for (let i = 0; i < this.excerptDataObj.Contours.Contour.length; i += 1) {
          if (this.excerptDataObj.Contours.Contour[i].EntitySpatial
          && this.excerptDataObj.Contours.Contour[i].EntitySpatial.spa_Borders
          && this.excerptDataObj.Contours.Contour[i].EntitySpatial.spa_Borders.spa_Border) {
            arr.push({ kad: `№${this.excerptDataObj.Contours.Contour[i].NumberRecord}`, borders: this.excerptDataObj.Contours.Contour[i].EntitySpatial.spa_Borders.spa_Border })
          }
        }
      }

      if (this.excerptData.Parcels && this.excerptData.Parcels.OffspringParcel && this.excerptData.Parcels.OffspringParcel.length > 0) {
        for (let i = 0; i < this.excerptData.Parcels.OffspringParcel.length; i += 1) {
          if (this.excerptData.Parcels.OffspringParcel[i].EntitySpatial
          && this.excerptData.Parcels.OffspringParcel[i].EntitySpatial.spa_Borders
          && this.excerptData.Parcels.OffspringParcel[i].EntitySpatial.spa_Borders.spa_Border) {
            arr.push({ kad: `№${this.excerptData.Parcels.OffspringParcel[i].CadastralNumber}`, borders: this.excerptData.Parcels.OffspringParcel[i].EntitySpatial.spa_Borders.spa_Border })
          }
        }
      }

      if (arr) {
        return arr
      }
      return []
    },
  },
  setup(props) {
    const coordSystems = computed(() => {
      if (!props.excerptData.CoordSystems || !props.excerptData.CoordSystems.spa_CoordSystem) return undefined
      const coords = {}
      if (props.excerptData.CoordSystems.spa_CoordSystem.constructor.name === 'Array') {
        for (let i = 0; i < props.excerptData.CoordSystems.spa_CoordSystem.length; i += 1) {
          coords[props.excerptData.CoordSystems.spa_CoordSystem[i].CsId] = props.excerptData.CoordSystems.spa_CoordSystem[i].Name
        }
      } else if (props.excerptData.CoordSystems.spa_CoordSystem.constructor.name === 'Object') {
        coords[props.excerptData.CoordSystems.spa_CoordSystem.CsId] = props.excerptData.CoordSystems.spa_CoordSystem.Name
      } else {
        return undefined
      }
      return coords
    })

    const getCoordSysName = code => {
      if (!code) { return '' }
      if (!coordSystems.value) return ''
      if (coordSystems.value[code]) { return coordSystems.value[code] }
      return ''
    }

    const subPoints = computed(() => {
      const arr = []
      if (props.excerptDataObj.SubParcels && props.excerptDataObj.SubParcels.SubParcel && props.excerptDataObj.SubParcels.SubParcel.length > 0) {
        for (let k = 0; k < props.excerptDataObj.SubParcels.SubParcel.length; k += 1) {
          const sp = props.excerptDataObj.SubParcels.SubParcel[k]
          const subarr = []
          if (sp && sp.EntitySpatial && sp.EntitySpatial.spa_SpatialElement && sp.EntitySpatial.spa_SpatialElement.length > 0) {
            for (let i = 0; i < sp.EntitySpatial.spa_SpatialElement.length; i += 1) {
              for (let j = 0; j < sp.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit.length; j += 1) {
                const unit = sp.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit[j]
                subarr.push(unit)
              }
            }
          }
          const coordName = getCoordSysName(sp.EntitySpatial && sp.EntitySpatial.EntSys ? sp.EntitySpatial.EntSys : '')
          arr.push({
            arr: subarr,
            title: `Номер части: ${sp.NumberRecord}${coordName ? ` | Cистема координат: ${coordName}` : ''} | Точек: ${subarr.length} шт`,
          })
        }
      }
      return arr
    })

    return {
      subPoints,
      getCoordSysName,
      currentPage: 1,
      currentSubPointsPage: 1,
      currentSubChartsPage: 1,
      printAdres,
      printAssignationCode,
      printAssignationType,
      printLevel,
      printDate,
      printParentCadastralNumbers,
      printConstructors,
      printState,
      printOwner,
      printStrArray,
      printOldNumbers,
      printDuration,
      pn,
      printCadastrCost,
      printCulturalHeritage,
      printObjectType,
      printOffspringObjects,
      printDoc,
      printRegistration,
      printArea,
      printCadastralBlock,
      printCategoryZU,
      printCoordSystems,
      printUtilization,
      printZUName,
      printNaturalObject,
      printEncumbrance,
      bordersFields: [
        { key: 'index', label: 'Номер п/п' },
        { key: 'Point1', label: 'Начальная точка' },
        { key: 'Point2', label: 'Конечная точка' },
        { key: 'p1', label: 'Дирекционный угол' },
        { key: 'p2', label: 'Горизонтальное проложение, м' },
        { key: 'spa_Edge.spa_Definition', label: 'Описание закрепления на местности' },
        { key: 'p4', label: 'Кадастровые номера смежных участков' },
        { key: 'p5', label: 'Сведения об адресах правообладателей смежных земельных участков' },
      ],
      subChartsFields: [
        { key: 'NumberRecord', label: 'Учетный номер части' },
        { key: 'Area', label: 'Площадь (м2)' },
        { key: 'Encumbrance', label: 'Содержание ограничения в использовании или ограничения права на объект недвижимости или обременения объекта недвижимости' },
      ],
      fields: [
        { key: 'NumberRecord', label: 'Учетный номер части' },
        { key: 'Area', label: 'Площадь, м²' },
        { key: 'Location', label: 'Описание местоположения части' },
        { key: 'EncumbranceText', label: 'Содержание ограничения в использовании, ограничения права на объект недвижимости, обременения объекта недвижимости' },
      ],
      fieldsPoints: [
        { key: 'SuNmb', label: 'Номер точки' },
        { key: 'spa_Ordinate.X', label: 'X' },
        { key: 'spa_Ordinate.Y', label: 'Y' },
        { key: 'spa_Ordinate.GeopointZacrep', label: 'Описание закрепления на местности' },
        { key: 'spa_Ordinate.DeltaGeopoint', thClass: 'mw100', label: 'Средняя квадратическая погрешность определения координат характерных точек границ земельного участка, м' },
      ],
    }
  },
}
</script>

<style scope>
.mw100 {
  max-width: 200px !important;
}
.excerpt-preview [role=columnheader] div{
  font-size: 0.6rem;
}

.excerpt-preview [role=columnheader]{
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.excerpt-preview [role=row]{
  font-size: 1rem;
}

.excerpt-preview [role=cell]{
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
</style>
