<template>
  <div class="row">
    <div class="col-12">
      <div v-if="title && title !== 'global'">
        <span v-if="canvasId">План участка: <b>{{ title.replace('_' + canvasId, '') }}</b></span>
        <span v-else>План участка: <b>{{ title }}</b></span>
      </div>
      <canvas
        :id="'convas' + title"
        style="background: rgba(0,0,0,0); border: 1px solid gray; width: 100%; display: block;"
      />
    </div>
  </div>
</template>

<script>
import {
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import { onMounted, computed } from '@vue/composition-api'
import {
} from '@core/utils/filter'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
  },
  props: {
    parcel: Object,
    kad: String,
    canvasId: Number,
  },
  setup(props) {
    const title = computed(() => {
      if (props.kad) {
        if (props.canvasId) {
          return `${props.kad}_${props.canvasId}`
        }
        return props.kad
      }
      if (props.parcel) {
        if (props.parcel.CadastralNumber) return props.parcel.CadastralNumber
        if (props.parcel.NumberRecord) {
          return `${props.parcel.NumberRecord}`
        }
      }
      if (props.canvasId) {
        return `_${props.canvasId}`
      }
      return `_${props.canvasId}`
    })
    onMounted(() => {
      const canvas = document.getElementById(`convas${title.value}`)
      const ctx = canvas.getContext('2d')
      /*
      const drawRect = (x, y, width, height) => {
        ctx.fillRect(x, y, width, height)
      }
      */
      const drawLine = (scale, x1, y1, x2, y2) => {
        ctx.strokeStyle = 'green'
        ctx.lineWidth = 1 * ((window.innerWidth / canvas.offsetWidth) / scale)
        ctx.beginPath()
        // draw a red lin
        ctx.moveTo(x1, y1)
        ctx.lineTo(x2, y2)
        ctx.stroke()
        ctx.closePath()
      }

      const drawText = (text, x, y, size) => {
        ctx.font = `${size}px "montserrat", Helvetica, Arial, serif`
        ctx.fillText(text, x, y)
      }

      const draw = () => {
        canvas.width = window.innerWidth
        canvas.height = 700
        let scale = 1
        if (props.parcel.EntitySpatial
        && props.parcel.EntitySpatial.spa_SpatialElement
        && props.parcel.EntitySpatial.spa_SpatialElement.length > 0
        && props.parcel.EntitySpatial.spa_SpatialElement[0].spa_SpelementUnit
        && props.parcel.EntitySpatial.spa_SpatialElement[0].spa_SpelementUnit.length > 0
        ) {
          let pointsGroup = []
          /* eslint-disable camelcase */
          let min_x = parseFloat(props.parcel.EntitySpatial.spa_SpatialElement[0].spa_SpelementUnit[0].spa_Ordinate.X)
          let max_x = parseFloat(props.parcel.EntitySpatial.spa_SpatialElement[0].spa_SpelementUnit[0].spa_Ordinate.X)
          let min_y = parseFloat(props.parcel.EntitySpatial.spa_SpatialElement[0].spa_SpelementUnit[0].spa_Ordinate.Y)
          let max_y = parseFloat(props.parcel.EntitySpatial.spa_SpatialElement[0].spa_SpelementUnit[0].spa_Ordinate.Y)
          for (let i = 0; i < props.parcel.EntitySpatial.spa_SpatialElement.length; i += 1) {
            const points = []
            points.push({
              x: parseFloat(props.parcel.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit[0].spa_Ordinate.X),
              y: parseFloat(props.parcel.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit[0].spa_Ordinate.Y),
            })
            for (let j = 1; j < props.parcel.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit.length; j += 1) {
              const firstPoint = points[j - 1]
              const secondPoint = {
                x: parseFloat(props.parcel.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit[j].spa_Ordinate.X),
                y: parseFloat(props.parcel.EntitySpatial.spa_SpatialElement[i].spa_SpelementUnit[j].spa_Ordinate.Y),
              }
              if (firstPoint.x > max_x) max_x = firstPoint.x
              if (secondPoint.x > max_x) max_x = secondPoint.x
              if (firstPoint.x < min_x) min_x = firstPoint.x
              if (secondPoint.x < min_x) min_x = secondPoint.x

              if (firstPoint.y > max_y) max_y = firstPoint.y
              if (secondPoint.y > max_y) max_y = secondPoint.y
              if (firstPoint.y < min_y) min_y = firstPoint.y
              if (secondPoint.y < min_y) min_y = secondPoint.y
              points.push(secondPoint)
            }
            pointsGroup.push(points)
          }
          const x_scale = max_x - min_x
          const y_scale = max_y - min_y
          if (min_x < 0) {
            for (let j = 0; j < pointsGroup.length; j += 1) {
              const points = pointsGroup[j]
              for (let i = 0; i < points.length; i += 1) {
                // eslint-disable-next-line operator-assignment
                points[i].x = x_scale - (max_x - points[i].x)
              }
            }
            max_x -= min_x
            min_x = 0
          }
          if (min_y < 0) {
            for (let j = 0; j < pointsGroup.length; j += 1) {
              const points = pointsGroup[j]
              for (let i = 0; i < points.length; i += 1) {
                // eslint-disable-next-line operator-assignment
                points[i].y = y_scale - (max_y - points[i].y)
              }
            }
            max_y -= min_y
            min_y = 0
          }
          const scaleY = ((canvas.offsetWidth) / y_scale)
          const scaleX = ((canvas.offsetHeight) / x_scale)
          scale = Math.min(scaleY, scaleX) * (window.innerWidth / canvas.offsetWidth)

          ctx.scale(scale, scale)
          // ctx.translate(520, 0) // 0.74 scale
          // widht 620 -> 1975
          // (max_x - min_x) 293 - 935  коеф 3,1911
          // середина 520
          // window.innerWidth 1600
          ctx.clearRect(0, 0, canvas.offsetWidth, 300)
          for (let j = 0; j < pointsGroup.length; j += 1) {
            const points = pointsGroup[j]
            for (let i = 1; i < points.length; i += 1) {
              const firstPoint = points[i - 1]
              const secondPoint = points[i]
              const fX = x_scale - (firstPoint.x - min_x)
              const sX = x_scale - (secondPoint.x - min_x)
              drawLine(scale, firstPoint.y - min_y, fX, secondPoint.y - min_y, sX)
            }
          }

          pointsGroup = []
        } else {
          drawText('Для участка нет координат', 50, 70, 50)
        }
      }

      // Ready, set, go
      draw()
    })

    return {
      title,
    }
  },
}
</script>
