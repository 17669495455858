<template>
  <div>
    <b-table
      :current-page="subPointsPageNumbers"
      responsive="sm"
      :items="items"
      :fields="fieldsSubPoints"
      per-page="20"
      first-number
    />
    <b-pagination
      v-model="subPointsPageNumbers"
      :total-rows="items.length"
      per-page="20"
      last-number
    />
  </div>
</template>

<script>
import {
  BTable, BPagination,
} from 'bootstrap-vue'

import {
} from '@core/utils/filter'

export default {
  components: {
    BTable,
    BPagination,
  },
  props: {
    items: Array,
  },
  setup() {
    return {
      subPointsPageNumbers: 1,
      fieldsSubPoints: [
        { key: 'SuNmb', label: 'Номер точки' },
        { key: 'spa_Ordinate.X', label: 'X' },
        { key: 'spa_Ordinate.Y', label: 'Y' },
        { key: 'spa_Ordinate.GeopointZacrep', label: 'Описание закрепления на местности' },
        { key: 'spa_Ordinate.DeltaGeopoint', thClass: 'mw100', label: 'Средняя квадратическая погрешность определения координат характерных точек границы части земельного участка, м' },
      ],
    }
  },
}
</script>
